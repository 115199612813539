.seachStyle .searchList .listLabel {
  min-width: 88px !important;
}
.rowdata {
  display: flex;
  border-left: 1px solid #ebeef5;
}
.rowdata .el-col {
  border-top: 1px solid #ebeef5;
  padding: 20px;
  border-right: 1px solid #ebeef5;
}
.rowdata:nth-last-child(1) .el-col {
  border-bottom: 1px solid #ebeef5;
}
.dialog-footer {
  display: flex;
  justify-content: center;
}
.col-title {
  color: #909399;
  font-size: 14px;
  font-weight: bold;
}
